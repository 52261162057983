<template>
  <select class="q-ml-md" @change="updateLanguage()" v-model="$i18n.locale">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale.toUpperCase() }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return { locales: ["es", "en", "cat"] };
  },
  methods: {
    updateLanguage() {
      sessionStorage.setItem("locale", this.$i18n.locale);
    },
  },
};
</script>

<style></style>
